import React from 'react';

const style = require('./InputGroup.module.scss');

type InputGroupProps = {
  children: React.ReactNode;
  className?: string;
  [otherProps: string]: any;
};

const InputGroup = (props: InputGroupProps) => {
  const { children, className, ...rest } = props;

  return (
    <div {...rest} className={`${className} ${style.inputGroup}`}>
      {children}
    </div>
  );
};

InputGroup.defaultProps = {
  className: '',
};

export default InputGroup;
