import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import BackButton from '../components/ui/BackButton';
import Button from '../components/ui/Button';
import Input from '../components/ui/Input';
import InputGroup from '../components/ui/InputGroup';
import Label from '../components/ui/Label';
import StepperLayout from '../components/layouts/StepperLayout';
import {
  getSolarCredits,
  setSolarCredits,
} from '../utils/localStorage/localStorageFunctions';
import { navigateAndSetActiveStep } from '../utils/utils';
import { StepUrl } from '../utils/enums';
import usePreloadPageData from '../hooks/usePreloadPageData';
import SEOLayout from '../components/layouts/SEOLayout';

const AboutYourSolarSchema = Yup.object().shape({
  solarCredits: Yup.number()
    .typeError('Solar credits must be a number')
    .min(0.01, 'Solar credits must be more than 0')
    .max(10_000, 'Please check the solar credits value')
    .required('Please, specify your solar credits'),
});

const SolarInput = () => {
  const [initialFromValues] = useState(() => {
    const solarCredits = getSolarCredits();

    return {
      solarCredits: solarCredits?.toString() || '',
    };
  });

  const handleNext = (values: typeof initialFromValues) => {
    const solarCredits = parseFloat(values.solarCredits);

    setSolarCredits(solarCredits);

    navigateAndSetActiveStep(StepUrl.URL_SOLAR_SYSTEM_DETAILS);
  };

  const handleBack = () => {
    navigateAndSetActiveStep(StepUrl.URL_SOLAR_INSTALLED);
  };

  usePreloadPageData(
    StepUrl.URL_SOLAR_SYSTEM_DETAILS,
    StepUrl.URL_SOLAR_INSTALLED
  );

  return (
    <SEOLayout>
      <StepperLayout
        currentStage={2}
        currentStep={10}
        pageUrl={StepUrl.URL_SOLAR_INPUT}
      >
        <div className="group my-3">
          <Formik
            initialValues={initialFromValues}
            onSubmit={(values) => {
              handleNext(values);
            }}
            validationSchema={AboutYourSolarSchema}
          >
            {(formikProps) => (
              <Form>
                <h2 className="my-2">Solar generation exported</h2>
                <InputGroup className="my-2">
                  <Label htmlFor="input-solar-size">Solar credits</Label>
                  <Input
                    id="input-solar-size"
                    name="solarCredits"
                    type="number"
                    unit="kWh"
                    controls
                    min={0.01}
                    max={10_000}
                    step={0.01}
                  />
                </InputGroup>
                <Button
                  className="mt-5"
                  type="submit"
                  disabled={!formikProps.values.solarCredits}
                >
                  Continue
                </Button>
                <div className="my-3">
                  <BackButton text="Back" onClick={handleBack} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </StepperLayout>
    </SEOLayout>
  );
};

export default SolarInput;
